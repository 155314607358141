import { useCallback, useMemo, useState } from "react";
import { ApiListResponse, DataGridSearchParams, SearchFilter } from "@packages/service-api";
import { useDebounce, useSnackbar } from "@packages/theme-mui-v5";

import { getDataGridSortModel } from "./utils";
import { CACHE_BLOCK_SIZE } from "./AgGrid";
import {
  CsvExportParams,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams
} from "ag-grid-community";
import { deepClone } from "@packages/utils";

type DataGridSearch = {
  setSearchTerm: (term: string) => void;
  exportDataAsCsv?: (grid: GridReadyEvent, csvExportParams: CsvExportParams) => void;
  serverSideDatasource: IServerSideDatasource;
};

type DataGridSearchParamsProps<T> = DataGridSearchParams<T> & {
  searchTerm?: string;
  filter?: SearchFilter<T>;
  skipKeywordList?: string[];
  sortConfig?: { [key: string]: string };
  search: (searchParams: DataGridSearchParams<T>) => Promise<ApiListResponse<T>>;
  exportBlockSize?: number;
};

export const useGetServerSideDataSource = <T>({
  search,
  skipKeywordList,
  sortConfig,
  filters,
  size,
  exportBlockSize
}: DataGridSearchParamsProps<T>): DataGridSearch => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  const debouncedSearchValue = useDebounce(searchTerm, 300);

  const serverSideDatasource: IServerSideDatasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        try {
          params.api.showLoadingOverlay();
          const filter = deepClone(filters) || {};
          Object.keys(params.request.filterModel).map((key) => {
            if (params.request.filterModel[key].values?.length) {
              filter[key] = params.request.filterModel[key].values;
            }
          });

          const nextRows = await search({
            searchTerm: debouncedSearchValue,
            filters: filter,
            from: params.request.startRow,
            size: size || CACHE_BLOCK_SIZE,
            sortModel: getDataGridSortModel(params.request.sortModel, skipKeywordList, sortConfig)
          });

          params.success({ rowData: nextRows?.data, rowCount: nextRows?.total });
          if (!nextRows?.data?.length) {
            params.api.showNoRowsOverlay();
          } else {
            params.api.hideOverlay();
          }
        } catch (error) {
          params.fail();
          params.api.hideOverlay();
        }
      }
    };
  }, [debouncedSearchValue, filters, skipKeywordList, sortConfig]);

  const exportDataAsCsv = useCallback(
    async (params, csvExportParams) => {
      async function fetchPaginatedData() {
        const filter = deepClone(filters) || {};
        const filterModel = params.api.getFilterModel();
        Object.keys(filterModel).map((key) => {
          if (filterModel[key].values?.length) {
            filter[key] = filterModel[key].values;
          }
        });
        const pageSize = exportBlockSize || 500;
        const allData = [];
        const totalData = params.api.getDisplayedRowCount();
        const sortModel = params.columnApi.getColumnState().filter((s) => s.sort);
        const dataGridSortModel = getDataGridSortModel(sortModel, skipKeywordList, sortConfig);

        const totalPage = Math.ceil(totalData / pageSize);
        const reqQueue = [];

        for (let currentPage = 0; currentPage < totalPage; currentPage++) {
          reqQueue.push(
            search({
              searchTerm: debouncedSearchValue,
              filters: filter,
              from: currentPage ? currentPage * pageSize : 0,
              size: pageSize,
              sortModel: dataGridSortModel
            })
          );
        }

        try {
          await Promise.all(reqQueue).then((result) => {
            result.forEach((data) => {
              allData.push(...data.data);
            });
          });
        } catch (error) {
          enqueueSnackbar("Unable to create alarm modes. Please try again.", {
            variant: "error"
          });
        }

        return allData;
      }

      async function exportAllDataToCSV() {
        params.api.showLoadingOverlay();
        const allData = await fetchPaginatedData();
        if (allData.length) {
          await params.api.applyServerSideRowData({
            successParams: {
              rowData: allData,
              rowCount: allData?.length
            }
          });
          await params.api.exportDataAsCsv(csvExportParams);
          params.api.hideOverlay();
          enqueueSnackbar("Csv file downloaded successfully.", { variant: "success" });
        } else {
          enqueueSnackbar("Unable to create alarm modes. Please try again.", {
            variant: "error"
          });
        }
      }

      await exportAllDataToCSV();
    },
    [debouncedSearchValue, filters, skipKeywordList, sortConfig, exportBlockSize]
  );

  return {
    setSearchTerm,
    exportDataAsCsv,
    serverSideDatasource
  };
};
